<div class="wrapper">
  <header>
    <app-recycle-bin-queue-header
      [changeRequest]="changeRequest"
      (refresh)="receiveRefreshCommand($event)"
    ></app-recycle-bin-queue-header>
  </header>
  <nav>
    <app-recycle-bin-queue-navigation
      [changeRequest]="changeRequest"
      [refresh]="refresh"
    ></app-recycle-bin-queue-navigation>
  </nav>
  <main>
    <app-recycle-bin-queue-panel [changeRequest]="changeRequest"></app-recycle-bin-queue-panel>
  </main>
  <div class="live">
    <app-recycle-bin-queue-panel-live [changeRequest]="changeRequest"></app-recycle-bin-queue-panel-live>
  </div>
</div>
