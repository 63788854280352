import { Component, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import RecycleBinQueueItem from '../recycle-bin-queue.model';
import RecycleBinsQueuePanelComponent from '../recycle-bin-queue-panel/recycle-bin-queue-panel.component';
import RecycleBinsQueuePanelLiveComponent from '../recycle-bin-queue-panel-live/recycle-bin-queue-panel-live.component';

/**
 * Recycle bin queue component
 */
@Component({
  selector: 'app-recycle-bin-queue',
  styleUrls: ['./page-recycle-bin-queue.component.scss'],
  templateUrl: './page-recycle-bin-queue.component.html',
})
export default class RecycleBinQueuePageComponent {
  public changeRequest = new BehaviorSubject<RecycleBinQueueItem | undefined>(undefined);

  @ViewChild(RecycleBinsQueuePanelLiveComponent)
  public livePanel!: RecycleBinsQueuePanelLiveComponent;

  @ViewChild(RecycleBinsQueuePanelComponent)
  public panel!: RecycleBinsQueuePanelComponent;

  public refresh = false;

  /**
   * Receive a new change request
   * @param event The selected recycle bin queue item
   */
  public receiveChangeRequest(event: BehaviorSubject<RecycleBinQueueItem | undefined>): void {
    this.changeRequest = event;
  }

  /**
   * Receive a refresh command
   * @param event The refresh command
   */
  public receiveRefreshCommand(event: boolean): void {
    this.refresh = event;
  }
}
