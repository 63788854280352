import { Component, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import ILogger from '../../func-logging/logger.interface';
import User from '../../module-user/user.model';
import UserService from '../../module-user/user.service';
import { nil } from '../../types';

interface NavItem {
  exact: boolean;
  label: string;
  path: string;
  showFor: 'admin' | 'always' | 'user';
}

const navItems: NavItem[] = [
  { exact: true, label: 'Home', path: '', showFor: 'always' },
  {
    exact: false,
    label: 'Map',
    path: 'recycle-bin/map',
    showFor: 'always',
  },
  { exact: false, label: 'Privacy', path: 'privacy', showFor: 'always' },
  { exact: false, label: 'Legal', path: 'legal', showFor: 'always' },
  { exact: false, label: 'Account', path: 'account', showFor: 'user' },
];

/**
 *
 */
@Component({
  selector: 'app-navigation-bar',
  styleUrls: ['./navigation-bar.component.scss'],
  templateUrl: './navigation-bar.component.html',
})
export default class NavigationBarComponent implements OnInit {
  public currentUser = new Subject<User | nil>();
  public isAdmin = false;
  public navItems: NavItem[] = navItems;

  /**
   * Creates a new instance of the navigation bar
   * @param userService The user service
   * @param logger The logger
   */
  public constructor(
    private readonly userService: UserService,
    @Inject('Logger') private readonly logger: ILogger,
  ) {}

  /**
   * Log user out
   */
  public logout(): void {
    this.userService.signOut();
    this.currentUser.next(undefined);
  }

  /**
   * OnInit handler
   */
  public ngOnInit(): void {
    this.logger.debug('OnInit called');
    this.userService.getUser().subscribe();
    this.userService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.logger.debug(`Logged in user changed to ${JSON.stringify(user)}`);
        this.currentUser.next(user);
        this.isAdmin = user?.roles.includes('admin') ?? false;
      } else {
        this.logger.debug('User logged out');
        this.isAdmin = false;
        this.currentUser.next(undefined);
      }
    });
  }
}
