<main class="inset">
  <h1>Admin</h1>

  <div class="grid-container">
    <!-- Databases -->
    <div class="card">
      <a routerLink="databases">
        <img class="card-img-top" src="assets/images/sql.png" alt="Card image cap" />
        <div class="card-body">
          <h5 class="card-title">Databases</h5>
          <p class="card-text">Here you can add or remove the databases available for download.</p>
        </div>
      </a>
    </div>

    <!-- Recycle bins -->
    <div class="card">
      <a routerLink="recycleBins">
        <img class="card-img-top" src="assets/images/recycleBin.png" alt="Card image cap" />
        <div class="card-body">
          <h5 class="card-title">Recycle bins queue</h5>
          <p class="card-text">Here you can view the queue of recycle bins that need to be verified.</p>
        </div>
      </a>
    </div>
  </div>
</main>
