import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as mapboxgl from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';

import { config } from '../../../environments/environment';
import RecycleBin from '../../module-recycle-bin/recycle-bin.model';
import RecycleBinService from '../../module-recycle-bin/recycle-bin.service';
import RecycleBinQueueItem from '../recycle-bin-queue.model';

/**
 * The recycle bin queue panel live component
 */
@Component({
  selector: 'app-recycle-bin-queue-panel-live',
  styleUrls: ['./recycle-bin-queue-panel-live.component.scss'],
  templateUrl: './recycle-bin-queue-panel-live.component.html',
})
export default class RecycleBinQueuePanelLiveComponent implements OnInit {
  private map!: mapboxgl.Map;
  private recycleBinDataService: RecycleBinService;
  private token: string = mapboxgl.accessToken;
  @Input() public changeRequest!: BehaviorSubject<RecycleBinQueueItem | undefined>;
  public panel!: UntypedFormGroup;
  public recycleBin?: RecycleBin;

  /**
   * Creates a new instance of the component
   * @param recycleBinDataService The recycle bin service
   * @param formBuilder The form builder
   */
  public constructor(
    recycleBinDataService: RecycleBinService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.recycleBinDataService = recycleBinDataService;
    this.token = config.mapBoxToken;
  }

  /**
   * OnInit lifecycle handler
   */
  public ngOnInit(): void {
    // Build form
    this.panel = this.formBuilder.group({
      category: [''],
      identifier: [''],
      isPaid: [''],
      source: [''],
    });

    this.changeRequest.subscribe((changeRequest) => {
      if (changeRequest && changeRequest.requestType !== 'insert') {
        setTimeout(() => {
          this.map = new mapboxgl.Map({
            accessToken: this.token,
            center: [0, 0],
            container: 'map-live',
            style: 'mapbox://styles/mapbox/streets-v11',
            zoom: 1,
          });
          this.map.scrollZoom.disable();
          this.map.dragPan.disable();
          this.map.dragRotate.disable();

          // Get the id
          const id = changeRequest?.id;
          if (id !== undefined || id != undefined) {
            // Fetch the recycle bin from the database with this id
            this.recycleBinDataService.getRecycleBinById(id).subscribe((bin) => {
              this.recycleBin = bin;

              this.map?.setCenter(new mapboxgl.LngLat(bin.longitude, bin.latitude));
              this.map?.setZoom(17);
            });
          }
        }, 0);
      }
    });
  }
}
