<table class="table table-striped table-bordered table-hover">
  <tbody *ngFor="let changeRequest of changeRequests" [id]="changeRequest.docId">
    <tr (click)="selected(changeRequest)">
      <div *ngIf="selectedChangeRequest.docId === changeRequest.docId; then thenBlock; else elseBlock"></div>
      <ng-template #thenBlock
        ><td class="selected">{{ changeRequest.requestType }} ({{ changeRequest.createdAt | date }})</td></ng-template
      >
      <ng-template #elseBlock
        ><td class="unselected">{{ changeRequest.requestType }} ({{ changeRequest.createdAt | date }})</td></ng-template
      >
    </tr>
  </tbody>
</table>
