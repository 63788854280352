import { NgModule } from '@angular/core';

import LocalStorageProvider from './local-storage.storage.provider';

/**
 * Storage module
 */
@NgModule({
  providers: [{ provide: 'StorageProvider', useClass: LocalStorageProvider }],
})
export default class StorageModule {}
