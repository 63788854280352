import { defaults } from './defaults';
import { Config, EnvironmentMode } from './environment.interface';

export const config: Config = {
  apiUrl: process.env.NG_APP_API_URL,
  defaults,
  environment: process.env.NG_APP_ENVIRONMENT as EnvironmentMode,
  mapBoxToken: process.env.NG_APP_MAPBOX_TOKEN,
  sentryDsn: process.env.NG_APP_SENTRY_DSN,
  sentryEnabled: process.env.NG_APP_SENTRY_ENABLED,
  sentryErrorSampleRate: process.env.NG_APP_SENTRY_ERROR_SAMPLE_RATE,
  sentryTraceSampleRate: process.env.NG_APP_SENTRY_TRACE_SAMPLE_RATE,
};
