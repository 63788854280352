<main class="inset">
  <h1>Legal</h1>
  <div class="grid-container">
    <!-- Icons8 -->
    <div class="card">
      <a href="https://icons8.com" target="_blank">
        <img class="card-img-top" src="assets/images/icons8.png" alt="Card image cap" />
        <div class="card-body">
          <h5 class="card-title">Icons8</h5>
          <p class="card-text">The icons of this website are made by Icons8.</p>
        </div>
      </a>
    </div>

    <!-- Data sources -->
    <div class="card">
      <a routerLink="data-sources">
        <img class="card-img-top" src="assets/images/data.png" alt="Card image cap" />
        <div class="card-body">
          <h5 class="card-title">Data sources</h5>
          <p class="card-text">The data sources used by this app and website.</p>
        </div>
      </a>
    </div>

    <!-- Icon sources -->
    <div class="card">
      <a routerLink="icon-sources">
        <img class="card-img-top" src="assets/images/data.png" alt="Card image cap" />
        <div class="card-body">
          <h5 class="card-title">Icon sources</h5>
          <p class="card-text">The sources of the icons used by this app and website.</p>
        </div>
      </a>
    </div>

    <!-- Databases -->
    <div class="card">
      <a routerLink="databases">
        <img class="card-img-top" src="assets/images/sql.png" alt="Card image cap" />
        <div class="card-body">
          <h5 class="card-title">Databases</h5>
          <p class="card-text">Here you can download the Binfinder database in various formats.</p>
        </div>
      </a>
    </div>
  </div>
</main>
