import { Component } from '@angular/core';

interface ListItem {
  description: string;
  image: {
    description: string;
    link: string;
  };
  title: string;
}

const listItems: ListItem[] = [
  {
    description:
      "Do you recognize this situation? You just emptied your drink and you want to throw it in the trash because you don't litter. Usually it takes a while before you come across a garbage can. With this app this situation is a thing of the past. When you need a dustbin, select the category you need - such as garbage, plastic, glass, clothing, dog... - and then you can use the GPS to navigate to the nearest bin.",
    image: {
      description: 'Image of a screen with four buttons you can press for the different categories of recycle bins.',
      link: 'assets/images/app-screens/search-screen.png',
    },
    title: 'Find the nearest recycle bin',
  },
  {
    description:
      'Since not all garbage cans are on the map yet, we are counting on you to add them so that other users - but of course also yourself - can navigate to the nearest bin without any problems.',
    image: {
      description: 'Image of a screen where you can submit a new recycle bin.',
      link: 'assets/images/app-screens/add-bin-screen.png',
    },
    title: 'Add new recycle bins',
  },
  {
    description:
      'When the app has found the nearest recycle bin within a reasonable distance, you can navigate to the recycle bin using your favorite navigation app installed on your device.',
    image: {
      description: 'Image of a screen with recycle bin details.',
      link: 'assets/images/app-screens/recycle-bin-details-screen.png',
    },
    title: 'Navigate',
  },
  {
    description:
      'When you want a quick overview of all the recycle bins in the area, you can check out the map screen. This screen displays an overview of all the nearby recycle bins.',
    image: {
      description: 'Image of a screen showing a map of all the recycle bins in the area.',
      link: 'assets/images/app-screens/map-screen.png',
    },
    title: 'Map',
  },
  {
    description:
      'Some recycle bins are not up-to-date anymore and need to be updated. This is possible using the edit screen. Just like the add screen this makes it extremely easy to submit an update to an existing recycle bin.',
    image: {
      description: 'Image of a screen where you can submit an update for a recycle bin.',
      link: 'assets/images/app-screens/edit-bin-screen.png',
    },
    title: 'Edit',
  },
];

/**
 * Home page
 */
@Component({
  selector: 'app-home',
  styleUrls: ['./page-home.component.scss'],
  templateUrl: './page-home.component.html',
})
export default class HomePageComponent {
  public listItems: ListItem[] = listItems;
}
