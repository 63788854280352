import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { Observable, Subject, of } from 'rxjs';
import { map, switchMap, tap, timeout } from 'rxjs/operators';

import { config } from '../../environments/environment';
import ILogger from '../func-logging/logger.interface';
import IStorage from '../func-storage/storage.interface';
import { IdToken, nil } from '../types';
import User, { LoginDTO, RegisterDTO, UserDTO } from './user.model';

/**
 * The user service
 */
@Injectable()
export default class UserService {
  private currentUser = new Subject<User | undefined>();

  /**
   * Create a new instance of the user service
   * @param httpClient The HTTP client
   * @param logger The logger
   * @param storageProvider The storage provider
   */
  public constructor(
    private httpClient: HttpClient,
    @Inject('Logger') private readonly logger: ILogger,
    @Inject('StorageProvider') private readonly storageProvider: IStorage,
  ) {}

  /**
   * Delete the current user
   * @returns The user
   */
  public deleteUser(): Observable<User | nil> {
    // Find the access token in storage system
    const token = this.storageProvider.find('access_token');

    // Abort early if no token could be found
    if (!token) {
      return of(undefined);
    }

    // Decode the token
    const decodedToken = jwtDecode<IdToken>(token);

    // Extract the user id from the token
    const userId = decodedToken.user_id;

    // Send HTTP request to delete the user
    return this.httpClient.delete(`${config.apiUrl}/v1/user/${userId}`).pipe(
      // Configure timeout
      timeout(config.defaults.timeout),

      // Convert response to string
      map((json) => JSON.stringify(json)),

      // Parse response to user DTO format
      map((json): UserDTO => JSON.parse(json)),

      // Convert DTO to user domain object
      map((dto) => new User(dto.name, dto.email, dto.points)),

      // Sign out the current user
      tap(() => this.signOut()),

      // Set the current logged in user
      tap(() => this.currentUser.next(undefined)),
    );
  }

  /**
   * Watch the current logged in user
   * @returns The current logged in user, or undefined if no user is logged in
   */
  public getCurrentUser(): Observable<User | undefined> {
    return this.currentUser.asObservable();
  }

  /**
   * Retrieve the current user info
   * @returns The user
   */
  public getUser(): Observable<User | nil> {
    // Find the access token in storage system
    const token = this.storageProvider.find('access_token');

    // Abort early if no token could be found
    if (!token) {
      return of(undefined);
    }

    // Decode the token
    const decodedToken = jwtDecode<IdToken>(token);

    // Extract the user id and roles from the token
    const userId = decodedToken.user_id;
    const roles = decodedToken.roles;

    // Send HTTP request to fetch user profile info
    return this.httpClient.get(`${config.apiUrl}/v1/user/${userId}`).pipe(
      // Configure timeout
      timeout(config.defaults.timeout),

      // Convert response to string
      map((json) => JSON.stringify(json)),

      // Parse response to user DTO
      map((json): UserDTO => JSON.parse(json)),

      // Convert DTO to user domain object
      map((dto) => {
        const user = new User(dto.name, dto.email, dto.points);
        user.roles = roles;
        return user;
      }),

      // Set the current logged in user
      tap((user) => this.currentUser.next(user)),
    );
  }

  /**
   * Send a password reset email
   * @param email The email address
   * @returns The response
   */
  public sendPasswordResetEmail(email: string): Observable<string> {
    return this.httpClient
      .post(`${config.apiUrl}/v2/user/password-reset`, { email }, { responseType: 'text' })
      .pipe(timeout(config.defaults.timeout));
  }

  /**
   * Sign in a user
   * @param email The email
   * @param password The password
   * @returns Firebase auth credentials
   */
  public signIn(email: string, password: string): Observable<User | nil> {
    return this.httpClient.post(`${config.apiUrl}/v2/user/login-with-password`, { email, password }).pipe(
      timeout(config.defaults.timeout),
      map((json) => JSON.stringify(json)),
      map((json): LoginDTO => JSON.parse(json)),
      switchMap((dto) => {
        this.storageProvider.save('access_token', dto.idToken);
        this.storageProvider.save('refresh_token', dto.refreshToken);
        return this.getUser();
      }),
      tap((user) => {
        if (user) {
          this.currentUser.next(user);
        }
      }),
    );
  }

  /**
   * Sign out a user
   */
  public signOut(): void {
    this.storageProvider.delete('access_token');
    this.storageProvider.delete('refresh_token');
    this.currentUser.next(undefined);
  }

  /**
   * Register a new user
   * @param username The username
   * @param email The email
   * @param password The password
   * @returns The newly registered user
   */
  public signUp(username: string, email: string, password: string): Observable<User | nil> {
    this.logger.debug(`Called signUp with parameters: ${username}, ${email}, ${password}`);
    const body = {
      email,
      password,
      username,
    };
    return this.httpClient.post(`${config.apiUrl}/user/register`, body).pipe(
      timeout(config.defaults.timeout),
      map((json) => JSON.stringify(json)),
      map((json): RegisterDTO => JSON.parse(json)),
      switchMap((dto) => {
        this.logger.debug(`signUp responded with: ${JSON.stringify(dto)}`);
        return this.signIn(email, password);
      }),
      tap((user) => {
        if (user) {
          this.currentUser.next(user);
        }
      }),
    );
  }
}
