import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import StorageModule from '../func-storage/module.storage';
import AuthenticationInterceptor from './authentication.interceptor';
import AccountPageComponent from './page-account/page-account.component';
import ForgotPasswordPageComponent from './page-forgot-password/page-forgot-password.component';
import LoginPageComponent from './page-login/page-login.component';
import PasswordResetInstructionsPageComponent from './page-password-reset-instructions/page-password-reset-instructions.component';
import SignUpPageComponent from './page-sign-up/page-sign-up.component';
import UserService from './user.service';

/**
 *
 */
@NgModule({
  declarations: [
    LoginPageComponent,
    ForgotPasswordPageComponent,
    PasswordResetInstructionsPageComponent,
    SignUpPageComponent,
    AccountPageComponent,
  ],
  imports: [RouterModule, ReactiveFormsModule, BrowserModule, HttpClientModule, StorageModule],
  providers: [AuthenticationInterceptor, UserService],
})
export default class UserModule {}
