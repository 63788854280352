import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import MapPageComponent from './page-map/page-map.component';
import RecycleBinService from './recycle-bin.service';

/**
 *
 */
@NgModule({
  declarations: [MapPageComponent],
  imports: [HttpClientModule],
  providers: [RecycleBinService],
})
export default class RecycleBinModule {}
