import { Injectable } from '@angular/core';

import { nil } from '../types';
import IStorage from './storage.interface';

/**
 * Local storage provider.
 */
@Injectable()
export default class LocalStorageProvider implements IStorage {
  /**
   * Delete the local storage item by the given key.
   * @param key The key of the item to delete
   */
  public delete(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * Find the local storage item by the given key.
   * @param key The key of the item to fetch
   * @returns The value of the local storage item
   */
  public find(key: string): nil | string {
    return localStorage.getItem(key);
  }

  /**
   * Add a new local storage item.
   * @param key The key of the new item
   * @param value The value of the new item
   */
  public save(key: string, value: string): void {
    localStorage.setItem(key, value);
  }
}
