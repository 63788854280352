import { Routes } from '@angular/router';

import AdminPageComponent from './module-admin/page-admin/page-admin.component';
import DatabaseListPageComponent from './module-database/page-database-list/page-database-list.component';
import ManageDatabasesPageComponent from './module-database/page-manage-databases/page-manage-databases.component';
import UploadDatabasePageComponent from './module-database/page-upload-database/page-upload-database.component';
import PageNotFoundComponent from './module-error/page-not-found/page-not-found.component';
import HomePageComponent from './module-home/page-home/page-home.component';
import DataSourcesPageComponent from './module-legal/page-data-sources/page-data-sources.component';
import IconSourcesPageComponent from './module-legal/page-icon-sources/page-icon-sources.component';
import LegalPageComponent from './module-legal/page-legal/page-legal.component';
import PrivacyPageComponent from './module-legal/page-privacy/page-privacy.component';
import MapPageComponent from './module-recycle-bin/page-map/page-map.component';
import RecycleBinQueuePageComponent from './module-recycle-bin-queue/page-recycle-bin-queue/page-recycle-bin-queue.component';
import AuthGuard from './module-user/auth-guard';
import AccountPageComponent from './module-user/page-account/page-account.component';
import ForgotPasswordPageComponent from './module-user/page-forgot-password/page-forgot-password.component';
import LoginPageComponent from './module-user/page-login/page-login.component';
import PasswordResetInstructionsPageComponent from './module-user/page-password-reset-instructions/page-password-reset-instructions.component';
import SignUpPageComponent from './module-user/page-sign-up/page-sign-up.component';

const userRoutes: Routes = [
  { component: LoginPageComponent, path: 'login' },
  { component: SignUpPageComponent, path: 'sign-up' },
  { component: ForgotPasswordPageComponent, path: 'forgot-password' },
  {
    component: PasswordResetInstructionsPageComponent,
    path: 'forgot-password/instructions',
  },
  {
    component: AccountPageComponent,
    path: 'account',
  },
];

const homeRoutes: Routes = [{ component: HomePageComponent, path: '' }];

const adminRoutes: Routes = [
  { canActivate: [AuthGuard], component: AdminPageComponent, path: 'admin' },
  {
    canActivate: [AuthGuard],
    component: RecycleBinQueuePageComponent,
    path: 'admin/recycleBins',
  },
  {
    canActivate: [AuthGuard],
    component: ManageDatabasesPageComponent,
    path: 'admin/databases',
  },
  {
    canActivate: [AuthGuard],
    component: UploadDatabasePageComponent,
    path: 'admin/database/upload',
  },
];

const recycleBinRoutes: Routes = [
  { component: MapPageComponent, path: 'recycleBin/map' },
  { component: MapPageComponent, path: 'recycle-bin/map' },
];

const legalRoutes: Routes = [
  { component: PrivacyPageComponent, path: 'privacy' },
  { component: LegalPageComponent, path: 'legal' },
  { component: DataSourcesPageComponent, path: 'legal/data-sources' },
  { component: IconSourcesPageComponent, path: 'legal/icon-sources' },
  { component: DatabaseListPageComponent, path: 'legal/databases' },
];

const errorRoutes: Routes = [{ component: PageNotFoundComponent, path: '**' }];

const routes = [...homeRoutes, ...adminRoutes, ...userRoutes, ...legalRoutes, ...recycleBinRoutes, ...errorRoutes];

export default routes;
