import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { nil } from '../../types';
import UserService from '../user.service';

/**
 *
 */
@Component({
  selector: 'app-forgot-password',
  styleUrls: ['./page-forgot-password.component.scss'],
  templateUrl: './page-forgot-password.component.html',
})
export default class ForgotPasswordPageComponent {
  public form = new FormGroup({
    email: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    }),
  });

  public pageState: 'done' | 'error' | 'loading' = 'done';

  /**
   * Creates a new instance of the component
   * @param userService The user service
   * @param router The router
   */
  public constructor(
    private readonly userService: UserService,
    private readonly router: Router,
  ) {}

  /**
   * Form submit logic
   */
  public onSubmit(): void {
    this.pageState = 'loading';
    const email = this.email;
    if (email) {
      this.userService.sendPasswordResetEmail(email.value).subscribe(
        () => {
          this.pageState = 'done';
          this.router.navigate(['/forgot-password/instructions']);
        },
        () => {
          this.pageState = 'error';
        },
      );
    }
  }

  /**
   * Email form control
   * @returns The email form control
   */
  public get email(): AbstractControl | nil {
    return this.form.get('email');
  }
}
