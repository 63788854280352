import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import RecycleBinQueueItem from '../recycle-bin-queue.model';
import RecycleBinQueueService from '../recycle-bin-queue.service';

/**
 * The recycle bin queue header
 */
@Component({
  selector: 'app-recycle-bin-queue-header',
  styleUrls: ['./recycle-bin-queue-header.component.scss'],
  templateUrl: './recycle-bin-queue-header.component.html',
})
export default class RecycleBinsQueueHeaderComponent implements OnInit {
  @Input() public changeRequest!: BehaviorSubject<RecycleBinQueueItem | undefined>;
  public loading = false;
  // eslint-disable-next-line unicorn/prefer-event-target
  @Output() public refresh = new EventEmitter<boolean>();

  /**
   * Creates a new instance of the component
   * @param recycleBinQueueService Recycle bin queue service
   */
  public constructor(private recycleBinQueueService: RecycleBinQueueService) {}

  /**
   * Accept the change request
   */
  public accept(): void {
    if (!this.changeRequest.value) {
      return;
    }
    this.loading = true;
    this.recycleBinQueueService.acceptChangeRequest(this.changeRequest.value.docId).subscribe(() => {
      this.loading = false;
      this.refresh.emit(true);
    });
  }

  /**
   * OnInit handler
   */
  public ngOnInit(): void {
    this.changeRequest.subscribe();
  }

  /**
   * Reject a change request
   */
  public reject(): void {
    if (!this.changeRequest.value) {
      return;
    }
    this.loading = true;
    this.recycleBinQueueService.rejectChangeRequest(this.changeRequest.value.docId).subscribe(() => {
      this.loading = false;
      this.refresh.emit(true);
    });
  }
}
