import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import ILogger from '../../func-logging/logger.interface';
import UserService from '../user.service';

/**
 * The account page component
 */
@Component({
  selector: 'app-account',
  styleUrls: ['./page-account.component.scss'],
  templateUrl: './page-account.component.html',
})
export default class AccountPageComponent implements OnInit {
  public pageState: 'done' | 'error' | 'loading' = 'loading';
  public userProfile: { key: string; value: string }[] = [];

  /**
   * Creates a new instance of the component
   * @param userService The user service
   * @param router The router
   * @param logger The logger
   */
  public constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    @Inject('Logger') private readonly logger: ILogger,
  ) {}

  /**
   * Delete the user
   */
  public deleteUser(): void {
    this.userService.deleteUser().subscribe(() => {
      this.router.navigateByUrl('');
    });
  }

  /**
   * OnInit handler
   */
  public ngOnInit(): void {
    this.logger.debug('AccountPage onInit called');
    this.userService.getUser().subscribe();
    this.userService.getCurrentUser().subscribe((user) => {
      this.pageState = 'done';
      if (!user) {
        this.router.navigateByUrl('');
        return;
      }
      this.userProfile = [
        { key: 'Username', value: user.name },
        { key: 'Email', value: user.email ?? 'N/A' },
        { key: 'Points', value: String(user.points) },
      ];
    });
  }
}
