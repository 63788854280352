import { Component } from '@angular/core';

/**
 * Privacy page
 */
@Component({
  selector: 'app-privacy',
  styleUrls: ['./page-privacy.component.scss'],
  templateUrl: './page-privacy.component.html',
})
export default class PrivacyPageComponent {}
