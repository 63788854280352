<main class="inset full-width-outline">
  <h1>Privacy Policy</h1>
  <h2>Introduction</h2>
  <p>
    Tristan Diependael develops apps for iOS, Android and the Web. “Tristan Diependael” or “us” or “we” or “I” refers to
    the individual Tristan Diependael. This Privacy Policy applies when you use either the Binfinder website or the
    mobile app. Do not use our services if you do not agree with this Privacy Policy.
  </p>
  <h2>Information we collect</h2>
  <ul>
    <li>Username, email address, password</li>
    <li>Usage statistics: We use usage statistics to gain insights into how users use this app or website.</li>
    <li>Crash reports: We use anonymous crash reports to help diagnose and fix crashes.</li>
    <li>
      Performance data: We use anonymously collected performance data to diagnose performance issues in the app and
      website.
    </li>
  </ul>
  <h2>How do we collect this information?</h2>
  <ul>
    <li>
      Information you give us when you create an account. When you create an account, we will ask you for personal
      information like your username, email address.
    </li>
    <li>Automatically collected information like crash reports, usage statistics and performance data.</li>
  </ul>
  <h2>Children under the age of 16</h2>
  <p>
    Our services are not intended for children under the age of 16. We do not knowingly collect personal information
    from children under 16. If you are under 16, please do not provide any information on our website or mobile apps.
  </p>
  <h2>Mailing policy</h2>
  <p>
    You can always ask for support or send feedback via email. When you send us an email, we will always reply to your
    question or feedback. We won’t use your email address for anything else than replying. All conversations will be
    deleted within 90 days.
  </p>
  <h2>Dispute resolution</h2>
  <p>
    If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact us
    at
    <a href="mailto:binfindersupport@icloud.com">binfindersupport&#64;icloud.com</a>.
  </p>
  <h2>Future changes to this Privacy Policy</h2>
  <p>We may update this Privacy Policy as necessary to reflect changes we make to satisfy legal requirements.</p>
  <h2>Contact information</h2>
  <p>
    If you have any questions about this Privacy Policy, you can contact us at
    <a href="mailto:binfindersupport@icloud.com">binfindersupport&#64;icloud.com</a>.
  </p>
</main>
