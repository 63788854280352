import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import fileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { map, timeout } from 'rxjs/operators';

import { config } from '../../environments/environment';
import Database from './database.model';

/**
 *
 */
@Injectable()
export default class DatabaseService {
  /**
   * Creates a new instance of the Database repository.
   * @param http The http client used to make http requests.
   */
  public constructor(private http: HttpClient) {}

  /**
   * Adds a new database snapshot to the database.
   * @param name The name of the database snapshot.
   * @param file The file to upload to the storage bucket.
   */
  public async createDatabaseArchive(name: string, file: File): Promise<void> {
    // Upload file to storage bucket
    const formData = new FormData();
    formData.append('file', file, file.name);
    const url = await this.http
      .post(`${config.apiUrl}/database/upload`, formData, { responseType: 'text' })
      .toPromise();

    // Add database archive entry to the database
    await this.http.post(`${config.apiUrl}/database`, { fileUrl: url, name }).toPromise();
  }

  /**
   * Delete the database
   * @param id The id of the database to delete
   * @returns The HTTP response
   */
  public deleteDatabase(id: number): Observable<unknown> {
    return this.http.delete(`${config.apiUrl}/database/${id}`);
  }

  /**
   * Download a database snapshot.
   * @param url The url of the database snapshot to download.
   * @param name The name of the database snapshot.
   */
  public downloadDatabase(url: string, name: string): void {
    fileSaver.saveAs(url, `${name}.zip`);
  }

  /**
   * Fetches the archived database snapshots.
   * @returns The database snapshots.
   */
  public getDatabases(): Observable<Database[]> {
    return this.http.get(`${config.apiUrl}/database`).pipe(
      timeout(config.defaults.timeout),
      map((list: unknown) => (Array.isArray(list) ? list.map((item) => Database.fromJSON(item)) : [])),
    );
  }
}
