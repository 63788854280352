<main class="inset">
  <h1>Databases</h1>
  <button id="uploadButton" class="btn btn-outline-primary" (click)="uploadDatabaseClicked()">Upload</button>
  <table class="table table-striped table-bordered">
    <thead>
      <tr>
        <td>Name</td>
        <td>Delete</td>
      </tr>
    </thead>
    <tbody *ngFor="let database of databases | async">
      <tr>
        <td>{{ database.name }}</td>
        <td class="minimal-space">
          <div class="deleteButton">
            <button class="btn btn-outline-danger" (click)="deleteDatabase(database.id)">delete</button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</main>
