import { Component } from '@angular/core';

/**
 * Admin page
 */
@Component({
  selector: 'app-admin',
  styleUrls: ['./page-admin.component.scss'],
  templateUrl: './page-admin.component.html',
})
export default class AdminPageComponent {}
