import { NgModule } from '@angular/core';

import AdminPageComponent from './page-admin/page-admin.component';

/**
 *
 */
@NgModule({
  declarations: [AdminPageComponent],
})
export default class AdminModule {}
