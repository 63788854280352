import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { version } from '../package.json';
import MainModule from './app/app.module';
import { config } from './environments/environment';

if (config.environment !== 'development') {
  enableProdMode();
}

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    enabled: config.environment !== 'development' && config.sentryEnabled === 'true',
    environment: config.environment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
        tracingOrigins: [config.apiUrl],
      }),
    ],
    release: `binfinder-web@${version}`,
    tracesSampleRate: 1,
  });
}

platformBrowserDynamic().bootstrapModule(MainModule);
