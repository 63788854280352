<div class="container">
  <!-- Shared state -->
  <div>
    <h1>User Profile</h1>
  </div>

  <!-- Loading state -->
  <div *ngIf="pageState === 'loading'">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <!-- Error state -->
  <div *ngIf="pageState === 'error'">
    <div class="alert alert-danger" role="alert">An unexpected error occurred! Try again later.</div>
  </div>

  <!-- Done state -->
  <div *ngIf="pageState === 'done'">
    <table class="table table-bordered">
      <tbody>
        <tr *ngFor="let profileItem of userProfile">
          <td>{{ profileItem.key }}</td>
          <td>{{ profileItem.value }}</td>
        </tr>
      </tbody>
    </table>
    <button class="btn btn-danger" data-toggle="modal" data-target="#deleteUserModal">Delete My Account</button>
    <div class="modal" id="deleteUserModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Account</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to delete your account? This action cannot be undone.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteUser()">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
