import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import Database from '../database.model';
import DatabaseService from '../database.service';

/**
 * Manage databases
 */
@Component({
  selector: 'app-manage-databases',
  styleUrls: ['./page-manage-databases.component.scss'],
  templateUrl: './page-manage-databases.component.html',
})
export default class ManageDatabasesPageComponent implements OnInit {
  private databases$!: Observable<Database[]>;

  /**
   * Creates a new instance of the component
   * @param databaseService The database service
   * @param router The router
   */
  public constructor(
    private databaseService: DatabaseService,
    private router: Router,
  ) {}

  /**
   * Delete the selected database
   * @param id The id of the database to delete
   */
  public async deleteDatabase(id: number): Promise<void> {
    await this.databaseService.deleteDatabase(id).toPromise();
    this.databases$ = this.databaseService.getDatabases();
    this.databases$.subscribe();
  }

  /**
   * Init handler
   */
  public ngOnInit(): void {
    this.databases$ = this.databaseService.getDatabases();
    this.databases$.subscribe();
  }

  /**
   * Clicked upload database button
   */
  public uploadDatabaseClicked(): void {
    this.router.navigate(['admin/database/upload']);
  }

  /**
   * List databases
   * @returns The list of databases
   */
  public get databases(): Observable<Database[]> {
    return this.databases$;
  }
}
