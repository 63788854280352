import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import DataSourcesPageComponent from './page-data-sources/page-data-sources.component';
import IconSourcesPageComponent from './page-icon-sources/page-icon-sources.component';
import LegalPageComponent from './page-legal/page-legal.component';
import PrivacyPageComponent from './page-privacy/page-privacy.component';

/**
 *
 */
@NgModule({
  declarations: [LegalPageComponent, DataSourcesPageComponent, IconSourcesPageComponent, PrivacyPageComponent],
  imports: [RouterModule],
})
export default class LegalModule {}
