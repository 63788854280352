import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import DatabaseService from './database.service';
import DatabaseListPageComponent from './page-database-list/page-database-list.component';
import ManageDatabasesPageComponent from './page-manage-databases/page-manage-databases.component';
import UploadDatabasePageComponent from './page-upload-database/page-upload-database.component';

/**
 *
 */
@NgModule({
  declarations: [DatabaseListPageComponent, ManageDatabasesPageComponent, UploadDatabasePageComponent],
  imports: [ReactiveFormsModule, BrowserModule],
  providers: [DatabaseService],
})
export default class DatabaseModule {}
