<main class="inset full-width-outline">
  <h1>404 - Not Found</h1>
  <p>
    Oops! It looks like you've stumbled upon a cosmic 404 error. The page you're searching for seems to have taken a
    detour through the vast reaches of cyberspace, leaving us unable to retrieve it. Don't worry, though! Our
    intergalactic team of developers is working tirelessly to locate this lost webpage. In the meantime, if this issue
    persists or if you need urgent assistance, feel free to reach out to our stellar support team. They're always ready
    to guide you back on track! Remember, even in the vastness of the digital universe, we're here to make your online
    journey smoother. Thank you for your patience as we navigate through the digital cosmos to bring you the content you
    seek.
  </p>
</main>
