<div class="container w-50 pt-5">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- Error state -->
    <div *ngIf="pageState === 'error'" class="alert alert-danger">An unexpected error occurred. Try again later!</div>

    <!-- Email -->
    <div class="form-group">
      <label for="email">Email</label>
      <input type="email" class="form-control" id="email" formControlName="email" required name="email" />
      <div *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="text-right">
        <p class="text-danger font-weight-light">Invalid email</p>
      </div>
    </div>

    <!-- Login -->
    <div class="form-group text-center">
      <button [disabled]="form.invalid" class="btn btn-primary w-50" type="submit">
        Reset password<span
          *ngIf="pageState === 'loading'"
          class="spinner-border spinner-border-sm loading-spinner"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    </div>
  </form>
</div>
