<div class="inset container wrapper">
  <!-- Error state -->
  <div *ngIf="pageState === 'error'" class="alert alert-danger">
    {{ errorMessage || 'An unexpected error occurred. Try again later!' }}
  </div>

  <form [formGroup]="loginForm" (ngSubmit)="signIn()">
    <!-- Email -->
    <div class="form-group">
      <label for="email">Email</label>
      <input type="email" class="form-control" id="email" formControlName="email" required name="email" />
      <div *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="text-right">
        <p class="text-danger font-weight-light">Invalid email</p>
      </div>
    </div>

    <!-- Password -->
    <div class="form-group">
      <label for="password">Password</label>
      <a [routerLink]="['/forgot-password']" class="float-right">Forgot password?</a>
      <input type="password" class="form-control" id="password" formControlName="password" required name="password" />
      <div *ngIf="password?.invalid && (password?.dirty || password?.touched)" class="text-right">
        <p class="text-danger font-weight-light">Invalid password</p>
      </div>
    </div>

    <!-- Login -->
    <div class="box">
      <div class="form-group text-center">
        <button class="btn btn-primary w-50" type="submit">
          Login<span
            *ngIf="pageState === 'loading'"
            class="spinner-border spinner-border-sm loading-spinner"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>

      <!-- Sign up -->
      <div class="form-group text-center">
        <button [routerLink]="['/sign-up']" class="btn btn-outline-secondary w-50">Sign up</button>
      </div>
    </div>
  </form>
</div>
