<main class="container inset">
  <h1>Binfinder</h1>
  <div class="container">
    <div *ngFor="let item of listItems; let i = index" class="row mb-4">
      <div class="col-12">
        <div class="d-flex flex-column flex-lg-row align-items-center">
          <div class="text-center mb-3 mb-lg-0 col-lg-6 order-lg-{{ i % 2 === 0 ? 'last' : 'first' }}">
            <img [src]="item.image.link" [alt]="item.image.description" class="img-fluid" />
          </div>
          <div class="flex-grow-1 pl-lg-3 col-lg-6">
            <h2>{{ item.title }}</h2>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
