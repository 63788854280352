import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, timeout } from 'rxjs/operators';

import { config } from '../../environments/environment';
import RecycleBin from './recycle-bin.model';

/**
 * Service responsible for communicating with the recycle bin server.
 */
@Injectable()
export default class RecycleBinService {
  /**
   * @param httpClient The http client used to send http calls.
   */
  public constructor(private httpClient: HttpClient) {}

  /**
   * Fetches the nearby recycle bins.
   * @param lat The latitude of the center coordinate.
   * @param lng The longitude of the center coordinate.
   * @returns An array of recycle bins near your location.
   */
  public fetchRecycleBins(lat: number, lng: number): Observable<RecycleBin[]> {
    const parameters = new HttpParams().set('lat', lat.toString()).set('lng', lng.toString());
    return this.httpClient.get(`${config.apiUrl}/recycleBin/fetch-nearby`, { params: parameters }).pipe(
      timeout(config.defaults.timeout),
      map((json) => JSON.stringify(json)),
      map((json): RecycleBin[] => JSON.parse(json)),
    );
  }

  /**
   * Fetches the recycle bin with the following id.
   * @param id The id of the recycle bin you want to fetch.
   * @returns The recycle bin with that id.
   */
  public getRecycleBinById(id: string): Observable<RecycleBin> {
    return this.httpClient.get(`${config.apiUrl}/recycleBin/${id}`).pipe(
      timeout(config.defaults.timeout),
      map((json) => JSON.stringify(json)),
      map((json): RecycleBin => JSON.parse(json)),
    );
  }

  /**
   * Adds a new recycle bin to the database.
   * @param recycleBin The recycle bin to add.
   * @returns Response
   */
  public insertRecycleBin(recycleBin: RecycleBin): Observable<unknown> {
    return this.httpClient.post(`${config.apiUrl}/recycleBin`, recycleBin).pipe(timeout(config.defaults.timeout));
  }

  /**
   * Removes a recycle bin.
   * @param id The id of the recycle bin to remove.
   * @returns Response
   */
  public removeRecycleBin(id: string): Observable<unknown> {
    return this.httpClient.delete(`${config.apiUrl}/recycleBin/${id}`).pipe(timeout(config.defaults.timeout));
  }

  /**
   * Updates a recycle bin.
   * @param recycleBin The recycle bin to update.
   * @returns Response
   */
  public updateRecycleBin(recycleBin: RecycleBin): Observable<unknown> {
    return this.httpClient
      .put(`${config.apiUrl}/recycleBin/${recycleBin.id}`, recycleBin)
      .pipe(timeout(config.defaults.timeout));
  }
}
