import { Component, OnInit } from '@angular/core';
import * as geolib from 'geolib';
import * as mapboxgl from 'mapbox-gl';

import { config } from '../../../environments/environment';
import RecycleBinService from '../recycle-bin.service';

/**
 *
 */
@Component({
  selector: 'app-map',
  templateUrl: './page-map.component.html',
})
export default class MapPageComponent implements OnInit {
  private map!: mapboxgl.Map;
  private markers: Array<mapboxgl.Marker> = [];
  private previousLatitude = 0;
  private previousLongitude = 0;
  private token: string = mapboxgl.accessToken;

  /**
   * Creates a new instance of the component
   * @param recycleBinService The recycle bin service
   */
  public constructor(private readonly recycleBinService: RecycleBinService) {
    this.token = config.mapBoxToken;
  }

  /**
   * Fetch the recycle bin and add these to the map
   * @param lat The center latitude coordinate
   * @param lng The center longitude coordinate
   */
  private fetchData(lat: number, lng: number): void {
    this.recycleBinService
      .fetchRecycleBins(lat, lng)
      .toPromise()
      .then((recycleBins) => {
        const mapboxMap = this.map;

        // Add points to map
        for (const bin of recycleBins ?? []) {
          const element = document.createElement('div');
          element.className = 'marker';
          element.style.width = '24px';
          element.style.height = '24px';
          element.style.backgroundSize = 'cover';
          element.style.cursor = 'pointer';

          switch (bin.category) {
            case 'trash': {
              element.style.backgroundImage = 'url("assets/images/trash.png")';
              break;
            }
            case 'glass': {
              element.style.backgroundImage = 'url("assets/images/glass.png")';
              break;
            }
            case 'dog': {
              element.style.backgroundImage = 'url("assets/images/dog.png")';
              break;
            }
            case 'clothing': {
              element.style.backgroundImage = 'url("assets/images/clothing.png")';
              break;
            }
          }

          const marker = new mapboxgl.Marker(element)
            .setLngLat(new mapboxgl.LngLat(bin.longitude, bin.latitude))
            .addTo(mapboxMap);

          this.markers.push(marker);
        }
      });
  }

  /**
   * OnInit lifecycle handler
   */
  public ngOnInit(): void {
    // eslint-disable-next-line unicorn/no-this-assignment, @typescript-eslint/no-this-alias
    const that = this;

    this.map = new mapboxgl.Map({
      accessToken: this.token,
      center: [0, 0],
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 1,
    });

    // Fetch current location
    navigator.geolocation.getCurrentPosition((position) => {
      // Center and move map
      that.map.setZoom(14);
      that.map.setCenter(new mapboxgl.LngLat(position.coords.longitude, position.coords.latitude));

      // Fetch recycle bins
      this.fetchData(position.coords.latitude, position.coords.longitude);
    });

    this.map.on('moveend', (event) => {
      // Get the center
      const center = event.target.getCenter();

      // Check if move is significant
      const fromCoordinate = {
        latitude: center.lat,
        longitude: center.lng,
      };
      const toCoordinate = {
        latitude: that.previousLatitude,
        longitude: that.previousLongitude,
      };
      const distance = geolib.getDistance(fromCoordinate, toCoordinate, 0.01);

      if (distance >= 1500) {
        // Reset previous coordinates
        that.previousLatitude = center.lat;
        that.previousLongitude = center.lng;

        // Remove all markers
        for (const element of that.markers) {
          element.remove();
        }

        // Fetch recycle bins
        that.fetchData(center.lat, center.lng);
      }
    });
  }
}
