import { NgModule } from '@angular/core';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import NgxLogger from './logger.ngx';

/**
 * Logging module
 */
@NgModule({
  imports: [
    LoggerModule.forRoot({
      level: NgxLoggerLevel.TRACE,
    }),
  ],
  providers: [{ provide: 'Logger', useClass: NgxLogger }],
})
export default class LoggingModule {}
