import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as mapboxgl from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';

import { config } from '../../../environments/environment';
import RecycleBinQueueItem from '../recycle-bin-queue.model';
import RecycleBinQueueService from '../recycle-bin-queue.service';

/**
 *
 */
@Component({
  selector: 'app-recycle-bin-queue-panel',
  styleUrls: ['./recycle-bin-queue-panel.component.scss'],
  templateUrl: './recycle-bin-queue-panel.component.html',
})
export default class RecycleBinsQueuePanelComponent implements OnInit {
  private map!: mapboxgl.Map;
  private token: string = mapboxgl.accessToken;
  @Input() public changeRequest!: BehaviorSubject<RecycleBinQueueItem | undefined>;
  public panel!: UntypedFormGroup;

  /**
   * Creates a new instance of the component
   * @param formBuilder The form builder
   * @param changeRequestService The change request service
   */
  public constructor(
    private formBuilder: UntypedFormBuilder,
    private changeRequestService: RecycleBinQueueService,
  ) {
    this.token = config.mapBoxToken;
  }

  /**
   * Category changed
   * @param event Event
   */
  public categoryChange(event: Event): void {
    if (!this.changeRequest.value) {
      return;
    }

    const target = event.target as HTMLInputElement;
    const category = target.value;
    const newChangeRequest = this.changeRequest.value;
    newChangeRequest.category = category;

    // Update change request
    this.changeRequestService.updateChangeRequest(this.changeRequest.value.docId, newChangeRequest).subscribe();

    this.changeRequest.next(newChangeRequest);
  }

  /**
   * OnInit lifecycle handler
   */
  public ngOnInit(): void {
    this.map = new mapboxgl.Map({
      accessToken: this.token,
      center: [0, 0],
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 1,
    });
    this.map.scrollZoom.disable();
    this.map.dragPan.disable();
    this.map.dragRotate.disable();

    // Build form
    this.panel = this.formBuilder.group({
      category: [''],
      identifier: [''],
      isPaid: [''],
      source: [''],
    });

    this.changeRequest.subscribe((changeRequest) => {
      if (changeRequest === undefined || changeRequest === null || changeRequest.latitude === undefined) {
        this.map?.setCenter(new mapboxgl.LngLat(0, 0));
        this.map?.setZoom(1);
      } else {
        this.map?.setCenter(new mapboxgl.LngLat(changeRequest?.longitude ?? 0, changeRequest.latitude));
        this.map?.setZoom(17);
      }
    });
  }

  /**
   * Handle paid change events
   * @param event Event
   */
  public paidChange(event: Event): void {
    if (!this.changeRequest.value) {
      return;
    }
    const target = event.target as HTMLInputElement;
    const paid = target.checked;
    const newChangeRequest = this.changeRequest.value;
    newChangeRequest.paid = paid;

    // Update change request
    this.changeRequestService.updateChangeRequest(this.changeRequest.value.docId, newChangeRequest).subscribe();

    this.changeRequest.next(newChangeRequest);
  }
}
