import { Component } from '@angular/core';

/**
 * Icon sources
 */
@Component({
  selector: 'app-icon-sources',
  templateUrl: './page-icon-sources.component.html',
})
export default class IconSourcesPageComponent {}
