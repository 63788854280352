<div class="wrapper match-parent">
  <nav class="navbar navbar-expand-lg navbar-dark">
    <a class="navbar-brand" href="#">Binfinder</a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li
          *ngFor="let item of navItems"
          class="nav-item"
          [routerLinkActiveOptions]="{ exact: item.exact }"
          [routerLinkActive]="['active']"
        >
          <a
            class="nav-link"
            [routerLink]="[item.path]"
            *ngIf="
              item.showFor === 'always' ||
              (item.showFor === 'admin' && isAdmin) ||
              (item.showFor === 'user' && (currentUser | async)?.name)
            "
            >{{ item.label }}</a
          >
        </li>
      </ul>

      <!-- Sign in / up area -->
      <ul class="navbar-nav">
        <div *ngIf="currentUser | async as user; else login">
          <div>
            <span class="pr-2">{{ user.name }}</span>
            <button class="btn btn-primary" (click)="logout()">Logout</button>
          </div>
        </div>
        <ng-template #login>
          <button class="btn btn-primary" [routerLink]="['login']">Login</button>
        </ng-template>
      </ul>
    </div>
  </nav>
  <main>
    <ng-content></ng-content>
  </main>
</div>
