<main class="inset">
  <h1>Databases</h1>
  <table class="table table-striped table-bordered">
    <thead>
      <tr>
        <td>Name</td>
        <td>Download</td>
      </tr>
    </thead>
    <tbody *ngFor="let database of databases | async">
      <tr>
        <td>{{ database.name }}</td>
        <td class="minimal-space">
          <div class="downloadButton">
            <button class="btn btn-outline-primary" (click)="downloadDatabase(database.fileUrl, database.name)">
              json
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</main>
