<main class="inset">
  <h1>Icon sources</h1>
  <ul>
    <li>
      <a href="https://boxicons.com" target="_blank" rel="noopener noreferrer">Boxicons</a>
    </li>
    <li>
      <a href="https://www.svgrepo.com/svg/210977/clothing-men" target="_blank" rel="noopener noreferrer"
        >Clothing icon</a
      >
    </li>
    <li>
      <a href="https://www.svgrepo.com/svg/259381/cigarette-smoke" target="_blank" rel="noopener noreferrer"
        >Cigarette icon</a
      >
    </li>
    <li>
      <a href="https://www.svgrepo.com/svg/259387/dog" target="_blank" rel="noopener noreferrer">Dog icon</a>
    </li>
    <li>
      <a href="https://www.svgrepo.com/svg/212135/glass" target="_blank" rel="noopener noreferrer">Glass icon</a>
    </li>
    <li>
      <a href="https://www.svgrepo.com/svg/8243/sheet" target="_blank" rel="noopener noreferrer">Paper icon</a>
    </li>
    <li>
      <a href="https://www.svgrepo.com/svg/228623/ecologic-plastic" target="_blank" rel="noopener noreferrer"
        >Plastic icon</a
      >
    </li>
    <li>
      <a href="https://www.svgrepo.com/svg/287780/garbage-bin" target="_blank" rel="noopener noreferrer">Trash icon</a>
    </li>
  </ul>
</main>
