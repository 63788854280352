import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import Database from '../database.model';
import DatabaseService from '../database.service';

/**
 * Databases list
 */
@Component({
  selector: 'app-databases',
  styleUrls: ['./page-database-list.component.scss'],
  templateUrl: './page-database-list.component.html',
})
export default class DatabaseListPageComponent implements OnInit {
  private databases$!: Observable<Database[]>;

  /**
   * Creates a new instance of the component
   * @param databaseService The database service
   */
  public constructor(private databaseService: DatabaseService) {}

  /**
   * Download a database
   * @param url The url of the database archive file
   * @param name The name of the database
   */
  public downloadDatabase(url: string, name: string): void {
    this.databaseService.downloadDatabase(url, name);
  }

  /**
   * OnInit handler
   */
  public ngOnInit(): void {
    this.databases$ = this.databaseService.getDatabases();
    this.databases$.subscribe();
  }

  /**
   * List the databases
   * @returns The list of databases
   */
  public get databases(): Observable<Database[]> {
    return this.databases$;
  }
}
