import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { config } from '../../environments/environment';
import RecycleBinQueueItem from './recycle-bin-queue.model';

/**
 * Recycle bin queue service
 */
@Injectable()
export default class RecycleBinQueueService {
  /**
   * Creates a new instance of the service
   * @param http The HTTP client
   */
  public constructor(private http: HttpClient) {}

  /**
   * Accept a change request
   * @param id The id of the change request to accept
   * @returns The response
   */
  public acceptChangeRequest(id: string): Observable<string> {
    const body = {
      id,
    };
    return this.http.post(`${config.apiUrl}/changeRequest/accept`, body, {
      responseType: 'text',
    });
  }

  /**
   * Fetch all recycle bin queue items
   * @returns The recycle bin queue items
   */
  public fetchAll(): Observable<RecycleBinQueueItem[]> {
    return this.http.get(`${config.apiUrl}/changeRequest`).pipe(
      map((json) => JSON.stringify(json)),
      map((json): RecycleBinQueueItem[] => JSON.parse(json)),
    );
  }

  /**
   * Reject a change request
   * @param id The id of the change request to reject
   * @returns The response
   */
  public rejectChangeRequest(id: string): Observable<string> {
    const body = {
      id,
    };
    return this.http.post(`${config.apiUrl}/changeRequest/reject`, body, {
      responseType: 'text',
    });
  }

  /**
   * Update a change request
   * @param id The id of the change request to update
   * @param changeRequest The updated change request
   * @returns The updated change request
   */
  public updateChangeRequest(id: string, changeRequest: RecycleBinQueueItem): Observable<RecycleBinQueueItem> {
    const body = changeRequest;
    return this.http.put(`${config.apiUrl}/changeRequest/${id}`, body).pipe(
      map((json) => JSON.stringify(json)),
      map((json): RecycleBinQueueItem => JSON.parse(json)),
    );
  }
}
