<div class="inset container wrapper">
  <!-- Error state -->
  <div *ngIf="pageState === 'error'" class="alert alert-danger">
    {{ errorMessage || 'An unexpected error occurred. Try again later!' }}
  </div>

  <form [formGroup]="userForm" name="sign-up-form" (ngSubmit)="onSubmit()">
    <!-- Username -->
    <div class="form-group">
      <label for="username">Username</label>
      <input type="text" class="form-control" id="username" formControlName="username" required name="username" />
      <div *ngIf="username?.invalid && (username?.dirty || username?.touched)" class="text-right">
        <p class="text-danger font-weight-light">Invalid username</p>
      </div>
    </div>

    <!-- Email -->
    <div class="form-group">
      <label for="email">Email</label>
      <input type="email" class="form-control" id="email" formControlName="email" required name="email" />
      <div *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="text-right">
        <p class="text-danger font-weight-light">Invalid email</p>
      </div>
    </div>

    <!-- Password -->
    <div class="form-group">
      <label for="password">Password</label>
      <input type="password" class="form-control" id="password" formControlName="password" required name="password" />
      <div *ngIf="password?.invalid && (password?.dirty || password?.touched)" class="text-right">
        <p class="text-danger font-weight-light">Invalid password</p>
      </div>
    </div>

    <!-- Confirm password -->
    <div class="form-group">
      <label for="confirmPassword">Confirm password</label>
      <input
        type="password"
        class="form-control"
        id="confirmPassword"
        formControlName="confirmPassword"
        required
        name="confirmPassword"
      />
      <div *ngIf="confirmPassword?.invalid && (confirmPassword?.dirty || confirmPassword?.touched)" class="text-right">
        <p class="text-danger font-weight-light">Invalid password</p>
      </div>
      <div
        *ngIf="userForm.errors?.['notSame'] === true && (confirmPassword?.dirty || confirmPassword?.touched)"
        class="text-right"
      >
        <p class="text-danger font-weight-light">Passwords should match</p>
      </div>
    </div>

    <div class="form-check form-group">
      <input
        class="form-check-input"
        type="checkbox"
        id="acceptPrivacyPolicyCheckBox"
        formControlName="privacyPolicy"
        required
        name="privacyPolicy"
      />
      <label class="form-check-label" for="acceptPrivacyPolicyCheckBox">Accept Privacy Policy</label>
      <div *ngIf="privacyPolicy?.invalid && (privacyPolicy?.dirty || privacyPolicy?.touched)" class="text-right">
        <p class="text-danger font-weight-light">You must accept the Privacy Policy</p>
      </div>
    </div>

    <!-- Sign up -->
    <div class="form-group text-center">
      <button [disabled]="userForm.invalid" class="btn btn-primary w-50" type="submit">
        Sign up<span
          *ngIf="pageState === 'loading'"
          class="spinner-border spinner-border-sm loading-spinner"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    </div>
  </form>
</div>
