import { Component } from '@angular/core';

/**
 * Legal page
 */
@Component({
  selector: 'app-legal',
  styleUrls: ['./page-legal.component.scss'],
  templateUrl: './page-legal.component.html',
})
export default class LegalPageComponent {}
