import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

import MainComponent from './main.component';
import AdminModule from './module-admin/admin.module';
import DatabaseModule from './module-database/database.module';
import HomeModule from './module-home/home.module';
import LegalModule from './module-legal/legal.module';
import RecycleBinModule from './module-recycle-bin/recycle-bin.module';
import RecycleBinQueueModule from './module-recycle-bin-queue/recycle-bin-queue.module';
import AuthenticationInterceptor from './module-user/authentication.interceptor';
import UserModule from './module-user/user.module';
import routes from './router';

/**
 * The app module
 */
@NgModule({
  bootstrap: [MainComponent],
  declarations: [MainComponent],
  imports: [
    RouterModule.forRoot(routes, {}),
    ReactiveFormsModule,
    UserModule,
    RecycleBinQueueModule,
    HomeModule,
    RecycleBinModule,
    AdminModule,
    LegalModule,
    DatabaseModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      deps: [Router],
      provide: Sentry.TraceService,
    },
    {
      deps: [Sentry.TraceService],
      multi: true,
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-empty-function, unicorn/consistent-function-scoping
      useFactory: () => () => {},
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
    },
  ],
})
export default class AppModule {}
