<h3>Live</h3>
<div *ngIf="(changeRequest | async)?.requestType === 'insert'; else form">
  <p>This view is only available with the 'update' or 'delete' request types.</p>
</div>
<ng-template #form>
  <form [formGroup]="panel">
    <!-- Identifier -->
    <div class="form-group">
      <label for="identifier-live">Identifier</label>
      <input class="form-control" type="text" placeholder="{{ recycleBin?.id }}" readonly />
    </div>

    <!-- Category -->
    <div class="form-group">
      <label for="category-live">Category</label>
      <input class="form-control" type="text" placeholder="{{ recycleBin?.category }}" readonly />
    </div>

    <!-- Paid -->
    <div class="form-group">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="isPaid-live" [checked]="recycleBin?.paid" disabled />
        <label class="form-check-label" for="isPaid-live">Paid</label>
      </div>
    </div>

    <!-- Source -->
    <div class="form-group">
      <label for="source-live">Source</label>
      <input class="form-control" type="text" placeholder="{{ recycleBin?.source }}" readonly />
    </div>

    <!-- Picture -->
    <div class="form-group">
      <label style="display: block" for="picture-live">Picture</label>
      <img
        id="picture-live"
        src="{{ recycleBin?.image || 'https://img.icons8.com/ultraviolet/80/000000/full-image.png' }}"
      />
    </div>

    <!-- Location -->
    <div class="form-group">
      <label style="display: block" for="map-live">Location</label>
      <div id="map-live" class="map"></div>
    </div>
  </form>
</ng-template>
