import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import RecycleBinQueuePageComponent from './page-recycle-bin-queue/page-recycle-bin-queue.component';
import RecycleBinQueueService from './recycle-bin-queue.service';
import RecycleBinsQueueHeaderComponent from './recycle-bin-queue-header/recycle-bin-queue-header.component';
import RecycleBinsQueueNavigationComponent from './recycle-bin-queue-navigation/recycle-bin-queue-navigation.component';
import RecycleBinsQueuePanelComponent from './recycle-bin-queue-panel/recycle-bin-queue-panel.component';
import RecycleBinQueuePanelLiveComponent from './recycle-bin-queue-panel-live/recycle-bin-queue-panel-live.component';

/**
 *
 */
@NgModule({
  declarations: [
    RecycleBinQueuePageComponent,
    RecycleBinsQueueHeaderComponent,
    RecycleBinsQueueNavigationComponent,
    RecycleBinsQueuePanelComponent,
    RecycleBinQueuePanelLiveComponent,
  ],
  imports: [ReactiveFormsModule, BrowserModule],
  providers: [RecycleBinQueueService],
})
export default class RecycleBinQueueModule {}
