<h3>{{ (changeRequest | async)?.requestType }} request</h3>
<form [formGroup]="panel">
  <!-- Identifier -->
  <div class="form-group">
    <label for="identifier">Identifier</label>
    <input class="form-control" type="text" placeholder="{{ (changeRequest | async)?.id }}" readonly />
  </div>

  <!-- Category -->
  <div class="form-group">
    <label for="category">Category</label>
    <select
      class="form-control"
      id="category"
      [value]="(changeRequest | async)?.category"
      (change)="categoryChange($event)"
    >
      <option>trash</option>
      <option>glass</option>
      <option>dog</option>
      <option>clothing</option>
    </select>
  </div>

  <!-- Paid -->
  <div class="form-group">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="isPaid"
        (change)="paidChange($event)"
        [checked]="(changeRequest | async)?.paid"
      />
      <label class="form-check-label" for="isPaid">Paid</label>
    </div>
  </div>

  <!-- Source -->
  <div class="form-group">
    <label for="source">Source</label>
    <input class="form-control" type="text" placeholder="{{ (changeRequest | async)?.source }}" readonly />
  </div>

  <!-- Picture -->
  <div class="form-group">
    <label style="display: block" for="picture">Picture</label>
    <img
      id="picture"
      src="{{ (changeRequest | async)?.image || 'https://img.icons8.com/ultraviolet/80/000000/full-image.png' }}"
    />
  </div>

  <!-- Location -->
  <div class="form-group">
    <label style="display: block" for="location">Location</label>
    <div id="map" class="map"></div>
  </div>
</form>
