import { Component } from '@angular/core';

/**
 * Password reset instructions page components
 */
@Component({
  selector: 'app-password-reset-instructions',
  styleUrls: ['./page-password-reset-instructions.component.scss'],
  templateUrl: './page-password-reset-instructions.component.html',
})
export default class PasswordResetInstructionsPageComponent {}
