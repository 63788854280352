import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { EMPTY, Observable } from 'rxjs';

import IStorage from '../func-storage/storage.interface';
import { IdToken } from '../types';

/**
 * Intercept all HTTP request and add the bearer token
 */
@Injectable()
export default class AuthenticationInterceptor implements HttpInterceptor {
  /**
   * Creates a new instance of the interceptor
   * @param storageProvider The storage provider
   */
  public constructor(@Inject('StorageProvider') private readonly storageProvider: IStorage) {}

  /**
   * Intercept logic
   * @param request The request
   * @param next The next function
   * @returns The http response
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Fetch the access token
    const idToken = this.storageProvider.find('access_token');

    // Abort early if no token could be found
    if (!idToken) {
      // Continue with the request
      return next.handle(request);
    }

    // Check if access token is still valid
    const decodedToken = jwtDecode<IdToken>(idToken);
    const tokenExpiresAt = decodedToken.exp ?? Date.now();
    if (Date.now() - 60 < tokenExpiresAt) {
      this.storageProvider.delete('access_token');
      this.storageProvider.delete('refresh_token');
      return EMPTY;
    }

    // Add access token to the request as a header
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    // Continue with the request
    return next.handle(request);
  }
}
