interface IDatabase {
  fileUrl: string;
  id: number;
  name: string;
}

/**
 * A database archive item
 */
export default class Database implements IDatabase {
  public fileUrl: string;
  public id!: number;
  public name: string;

  /**
   * Creates a new instance of a database archive item
   * @param name The name of the database
   * @param fileUrl The file download url
   */
  public constructor(name: string, fileUrl: string) {
    this.name = name;
    this.fileUrl = fileUrl;
  }

  /**
   * Creates a new instance from JSON
   * @param json The JSON
   * @returns The new database archive instance
   */
  public static fromJSON(json: IDatabase): Database {
    const database = new Database(json.name, json.fileUrl);
    database.id = json.id;
    return database;
  }

  /**
   * Convert the database archive item to json
   * @returns The JSON representation of a database archive item
   */
  public toJson(): IDatabase {
    return {
      fileUrl: this.fileUrl,
      id: this.id,
      name: this.name,
    } as IDatabase;
  }
}
