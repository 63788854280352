import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { nil } from '../../types';
import UserService from '../user.service';

/**
 * Login page
 */
@Component({
  selector: 'app-login',
  styleUrls: ['./page-login.component.scss'],
  templateUrl: './page-login.component.html',
})
export default class LoginPageComponent {
  public errorMessage = '';

  public readonly loginForm = new FormGroup({
    email: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    }),
    password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
  });

  public pageState: 'done' | 'error' | 'loading' = 'done';

  /**
   * Creates a new instance of the component
   * @param userService The user service
   * @param router The router
   */
  public constructor(
    private readonly userService: UserService,
    private readonly router: Router,
  ) {}

  /**
   * Submit form handler
   */
  public signIn(): void {
    const { email, password } = this.loginForm.value;
    if (email && password) {
      this.pageState = 'loading';
      this.errorMessage = '';
      this.userService.signIn(email, password).subscribe(
        () => {
          this.pageState = 'done';
          this.router.navigate(['/account']);
        },
        (error) => {
          this.pageState = 'error';
          this.errorMessage = error.error.message;
        },
      );
    }
  }

  /**
   * Email form control
   * @returns The email form control
   */
  public get email(): AbstractControl | nil {
    return this.loginForm.get('email');
  }

  /**
   * Password form control
   * @returns The password form control
   */
  public get password(): AbstractControl | nil {
    return this.loginForm.get('password');
  }
}
