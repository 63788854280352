import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import ILogger from '../../func-logging/logger.interface';
import { nil } from '../../types';
import UserService from '../user.service';

/**
 * Sign up component
 */
@Component({
  selector: 'app-sign-up',
  styleUrls: ['./page-sign-up.component.scss'],
  templateUrl: './page-sign-up.component.html',
})
export default class SignUpPageComponent {
  /**
   * Check if the passwords match
   * @param control The form controls
   * @returns If the passwords match
   */
  private checkPasswords: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const { confirmPassword, password } = control.value;
    // eslint-disable-next-line unicorn/no-null
    return password === confirmPassword ? null : { notSame: true };
  };

  public errorMessage = '';

  public pageState: 'done' | 'error' | 'loading' = 'done';

  public userForm = new FormGroup(
    {
      confirmPassword: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(8)],
      }),
      email: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.email],
      }),
      password: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(8)],
      }),
      privacyPolicy: new FormControl(false, {
        nonNullable: true,
        validators: [Validators.requiredTrue],
      }),
      username: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    },
    { validators: this.checkPasswords },
  );

  /**
   * Creates a new instance of the component
   * @param router The router
   * @param userService The user service
   * @param logger The logger
   */
  public constructor(
    private router: Router,
    private userService: UserService,
    @Inject('Logger') private readonly logger: ILogger,
  ) {}

  /**
   * Form submit handler
   */
  public onSubmit(): void {
    if (
      this.email?.valid &&
      this.username?.valid &&
      this.password?.valid &&
      this.confirmPassword?.valid &&
      this.privacyPolicy?.valid
    ) {
      this.pageState = 'loading';
      this.errorMessage = '';
      this.userService.signUp(this.username.value, this.email.value, this.password.value).subscribe(
        () => {
          this.pageState = 'done';
          this.logger.debug('User registration response received, navigating to home page');
          this.router.navigateByUrl('');
        },
        (error) => {
          this.pageState = 'error';
          this.errorMessage = error.error.message;
          this.logger.error(`Something went wrong while registering a new user: ${JSON.stringify(error)}`);
        },
      );
    }
  }

  /**
   * Confirm password form control
   * @returns The confirm password form control
   */
  public get confirmPassword(): AbstractControl | nil {
    return this.userForm.get('confirmPassword');
  }

  /**
   * Email form control
   * @returns The email form control
   */
  public get email(): AbstractControl | nil {
    return this.userForm.get('email');
  }

  /**
   * Password form control
   * @returns The password form control
   */
  public get password(): AbstractControl | nil {
    return this.userForm.get('password');
  }

  /**
   * Privacy Policy form control
   * @returns The Privacy Policy form control
   */
  public get privacyPolicy(): AbstractControl | nil {
    return this.userForm.get('privacyPolicy');
  }

  /**
   * Username form control
   * @returns The username form control
   */
  public get username(): AbstractControl | nil {
    return this.userForm.get('username');
  }
}
