<main class="inset">
  <!-- Title -->
  <h1>Upload database archive</h1>

  <!-- Error -->
  <div *ngIf="name?.invalid && validate" class="alert alert-danger">Invalid name</div>

  <!-- Form -->
  <form [formGroup]="database" (ngSubmit)="onSubmit()">
    <!-- Name -->
    <div class="form-group">
      <label for="name">Name</label>
      <input type="text" class="form-control" id="name" formControlName="name" required name="name" />
    </div>

    <!-- File -->
    <div class="form-group">
      <label for="file">File</label>
      <input
        type="file"
        class="form-control"
        id="file"
        formControlName="file"
        required
        name="file"
        accept=".zip"
        (change)="handleFileInput($event)"
      />
    </div>

    <!-- Upload -->
    <div class="form-group text-center">
      <button class="btn btn-primary w-50" type="submit">Upload</button>
    </div>
  </form>
</main>
