import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import ILogger from './logger.interface';

/**
 * Logger
 */
@Injectable()
export default class NgxLogger implements ILogger {
  /**
   * Creates a new instance of the logger
   * @param logger The logger
   */
  public constructor(private readonly logger: NGXLogger) {}

  /**
   * Log a debug message
   * @param message The debug message
   */
  public debug(message: string): void {
    this.logger.debug(message);
  }

  /**
   * Log an error message
   * @param message The error message
   */
  public error(message: string): void {
    this.logger.error(message);
  }

  /**
   * Log an info message
   * @param message The info message
   */
  public info(message: string): void {
    this.logger.info(message);
  }

  /**
   * Log a warning message
   * @param message The warning message
   */
  public warn(message: string): void {
    this.logger.warn(message);
  }
}
