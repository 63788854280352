import { Component } from '@angular/core';

/**
 *
 */
@Component({
  selector: 'app-root',
  templateUrl: './main.component.html',
})
export default class MainComponent {}
