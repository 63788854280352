import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import LoggingModule from '../func-logging/module.logging';
import UserModule from '../module-user/user.module';
import NavigationBarComponent from './navigation-bar/navigation-bar.component';
import HomePageComponent from './page-home/page-home.component';

/**
 *
 */
@NgModule({
  declarations: [HomePageComponent, NavigationBarComponent],
  exports: [NavigationBarComponent],
  imports: [RouterModule, BrowserModule, UserModule, LoggingModule],
})
export default class HomeModule {}
