/**
 * The user
 */
export default class User {
  public email: string | undefined;
  public name: string;
  public points: number;
  public roles: string[] = [];

  /**
   * Creates a new instance of a user entity
   * @param name The username of the user
   * @param email The email of the user
   * @param points The points of the user
   */
  public constructor(name: string, email: string | undefined, points: number) {
    this.name = name;
    this.email = email;
    this.points = points;
  }
}

/**
 * Register DTO
 */
export class RegisterDTO {
  public readonly token!: string;
  public readonly user!: User;
}

/**
 * User DTO
 */
export class UserDTO {
  public email!: string;
  public id!: string;
  public name!: string;
  public points!: number;
}

/**
 * Login DTO
 */
export class LoginDTO {
  public idToken!: string;
  public refreshToken!: string;
  public token!: string;
}
