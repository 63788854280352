import { Component } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { nil } from '../../types';
import DatabaseService from '../database.service';

/**
 * Upload database
 */
@Component({
  selector: 'app-upload-database',
  styleUrls: ['./page-upload-database.component.scss'],
  templateUrl: './page-upload-database.component.html',
})
export default class UploadDatabasePageComponent {
  private fileToUpload: File | nil = undefined;
  public database: UntypedFormGroup;
  public validate = false;

  /**
   * Creates a new instance of the component
   * @param formBuilder The form builder
   * @param databaseService The database service
   * @param router The router
   */
  public constructor(
    private formBuilder: UntypedFormBuilder,
    private databaseService: DatabaseService,
    private router: Router,
  ) {
    this.database = this.formBuilder.group({
      file: ['', [Validators.required]],
      name: ['', [Validators.required]],
    });
  }

  /**
   * Handle file upload
   * @param event File
   */
  public handleFileInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    if (files?.length) {
      this.fileToUpload = files.item(0);
    }
  }

  /**
   * On submit form logic
   */
  public async onSubmit(): Promise<void> {
    this.validate = true;
    if (this.fileToUpload) {
      await this.databaseService.createDatabaseArchive(this.database.value.name, this.fileToUpload);
      this.router.navigate(['admin/databases']);
    }
  }

  /**
   * Name form control
   * @returns Name form control
   */
  public get name(): AbstractControl | nil {
    return this.database.get('name');
  }
}
