import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import UserService from './user.service';

/**
 * Authentication guard
 * @param role The role to check against
 * @returns The can activate function
 */
const AuthGuard = (role: string): CanActivateFn => {
  return () => {
    const router = inject(Router);
    const userService = inject(UserService);
    return userService.getUser().pipe(
      map((user) => {
        if (!user) {
          return false;
        }
        if (user.roles.includes(role)) {
          return true;
        }
        router.navigate(['/login']);
        return false;
      }),
    );
  };
};

export default AuthGuard;
