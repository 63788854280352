import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import RecycleBinQueueItem from '../recycle-bin-queue.model';
import RecycleBinQueueService from '../recycle-bin-queue.service';

/**
 * The recycle bins queue navigation bar component
 */
@Component({
  selector: 'app-recycle-bin-queue-navigation',
  styleUrls: ['./recycle-bin-queue-navigation.component.scss'],
  templateUrl: './recycle-bin-queue-navigation.component.html',
})
export default class RecycleBinsQueueNavigationComponent implements OnInit, OnChanges {
  @Input() public changeRequest!: BehaviorSubject<RecycleBinQueueItem | undefined>;
  public changeRequests!: RecycleBinQueueItem[];
  @Input() public refresh!: boolean;
  public selectedChangeRequest!: RecycleBinQueueItem;

  /**
   * Creates a new instance of the component
   * @param recycleBinQueueService The recycle bin queue service
   */
  public constructor(private recycleBinQueueService: RecycleBinQueueService) {}

  /**
   * Fetch recycle bin queue data
   */
  private fetchData(): void {
    this.recycleBinQueueService.fetchAll().subscribe((changeRequests) => {
      this.changeRequests = changeRequests;
      if (this.changeRequests[0]) {
        this.changeRequest.next(this.changeRequests[0]);
        this.selectedChangeRequest = this.changeRequests[0];
      }
    });
  }

  /**
   * Handle change events
   */
  public ngOnChanges(): void {
    this.fetchData();
  }

  /**
   * OnInit handler
   */
  public ngOnInit(): void {
    this.fetchData();
  }

  /**
   * Handle change request selection
   * @param changeRequest The selected change request
   */
  public selected(changeRequest: RecycleBinQueueItem): void {
    this.changeRequest.next(changeRequest);
    this.selectedChangeRequest = changeRequest;
  }
}
