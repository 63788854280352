import { Component } from '@angular/core';

/**
 * Data sources
 */
@Component({
  selector: 'app-data-sources',
  templateUrl: './page-data-sources.component.html',
})
export default class DataSourcesPageComponent {}
